import React from "react"
import styled from "@emotion/styled"

import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import dimensions from "../styles/dimensions"
import colors from "../styles/colors"
import { withPrefix } from "gatsby"
import fleabagGIF from "../../content/assets/gifs/fleabag.gif"
import { IoLogoTwitter, IoLogoGithub, IoLogoLinkedin } from "react-icons/io"

const QuotesContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
  }
`

const ColContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
  }
`
const QuotesCard = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 100%;
  margin-bottom: 1em;
  p {
    width: 100%;
    a {
      color: ${colors.primary};
    text-decoration: none;
    border-bottom: 2px solid transparent;
    width: fit-content;
    padding-bottom: 0.1em;
    span {
      margin-left: 0.5em;
      transform: translateX(-4px);
      display: inline-block;
      transition: transform 400ms ease-in-out;
    }
  }
  a:hover {
    border-bottom: 2px solid ${colors.primary};
    transition: 100ms ease-in-out border-bottom;
    span {
      transform: translateX(0px);
      opacity: 1;
      transition: transform 150ms ease-in-out;
    }
  }
`
const AboutContentContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 4em;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
  }
`
const AboutImageContainer = styled("div")`
  .gatsby-image-wrapper {
    margin: auto;
    width: 60%;
    height: auto;
  }
  a {
    color: ${colors.primary};
    text-decoration: none;
    border-bottom: 2px solid transparent;
    width: fit-content;
    padding-bottom: 0.1em;
  }
  a:hover {
    border-bottom: 2px solid ${colors.primary};
    transition: 100ms ease-in-out border-bottom;
  }
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    .gatsby-image-wrapper {
      margin: auto;
      width: 70%;
      height: auto;
    }
    padding-bottom: 1em;
  }
`
const Highlight = styled("font")`
  color: ${colors.primary};
  font-weight: 600;
`
const AboutContactContainer = styled("div")`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.5em;
  width: 60%;
  padding: 1em;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    font-size: 0.7em;
  }
`

const AboutTextContainer = styled("div")`
  margin: auto;
  p {
    width: 100%;
    a {
      color: ${colors.primary};
    text-decoration: none;
    border-bottom: 2px solid transparent;
    width: fit-content;
    padding-bottom: 0.1em;
    span {
      margin-left: 0.5em;
      transform: translateX(-4px);
      display: inline-block;
      transition: transform 400ms ease-in-out;
    }
  }
  a:hover {
    border-bottom: 2px solid ${colors.primary};
    transition: 100ms ease-in-out border-bottom;
    span {
      transform: translateX(0px);
      opacity: 1;
      transition: transform 150ms ease-in-out;
    }
  }
`

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const avatar = data?.avatar?.childImageSharp?.sizes
  const photography = data?.photography?.childImageSharp?.sizes
  const travel = data?.travel?.childImageSharp?.sizes

  return (
    <Layout location={location} title={siteTitle} className="content">
      <div className="content title">
        <SEO title="About" />
        <h1>About</h1>
        <AboutContentContainer>
          <AboutImageContainer>
            <Image
              style={{ borderRadius: "1em" }}
              sizes={avatar}
              alt={"about-me-photo"}
            />
            <AboutContactContainer>
              <a
                href="https://github.com/ak2411"
                rel="noreferrer"
                target="_blank"
              >
                <span role="img" aria-label="github">
                  <IoLogoGithub size="1.5em" />
                </span>
              </a>
              <a
                href="http://www.linkedin.com/in/angelica-kosasih"
                rel="noreferrer"
                target="_blank"
              >
                <span role="img" aria-label="linkedin">
                  <IoLogoLinkedin size="1.5em" />
                </span>
              </a>
              <a
                href="https://twitter.com/aka_kosasih"
                rel="noreferrer"
                target="_blank"
              >
                <span role="img" aria-label="twitter">
                  <IoLogoTwitter size="1.5em" />
                </span>
              </a>

              <a
                href={withPrefix("/resume.pdf")}
                rel="noreferrer"
                target="_blank"
              >
                Resume
              </a>
            </AboutContactContainer>
          </AboutImageContainer>
          <AboutTextContainer>
            <h3>Hi!</h3>
            <p>
              I'm Angelica, some people call me Ko. I'm an AR/VR developer, Web developer, and UI/UX designer, living in the Bay Area.
            </p>
            <p>
              Currently, I run a newsletter called  <a href="https://www.xrresearched.substack.com" target="_blank" rel="noreferrer">XR researched</a> and a <a href="https://www.tiktok.com/@coffeewith_a" target="_blank" rel="noreferrer">Tiktok channel</a> introducing my favorite photographers.
            </p>
          </AboutTextContainer>
        </AboutContentContainer>
        <div style={{ textAlign: "center", paddingBottom: "4em" }}>
          <h4>Scroll to view some of my inspirations ↓</h4>
        </div>
        <QuotesContainer>
          <ColContainer>
            <QuotesCard>
              <h2>
                "So you have to trust that the dots will somehow connect in your
                future."
              </h2>{" "}
              <h3>- Steve Jobs</h3>
              <p style={{ marginTop: "1em" }}>
                By the end of my third year, I was working on projects that
                spanned from tech, to consulting, and design. At that time, I
                was worried that they were too diverse, but this commencement
                address helped me to trust in my interests and intuition.
                <br />
              </p>
              <p style={{ marginTop: "0.5em" }}>
                Turns out, the one commonality in all my projects is that they
                involve <Highlight>designing delightful products</Highlight> - I love
                the problem solving and collaboration that comes with taking the
                why to what and how.
              </p>
            </QuotesCard>
            <QuotesCard>
              <img
                src={fleabagGIF}
                alt="Fleabag"
                style={{
                  width: "80%",
                  borderRadius: "0.5em",
                  marginBottom: "0.1em",
                }}
              />
              <p
                style={{
                  fontSize: "0.6em",
                  color: colors.grey600,
                }}
              >
                Courtesy of Chalamet
              </p>
              <h3>
                I{" "}
                <span role="img" aria-label="heart">
                  ❤️
                </span>{" "}
                Movies/TV Shows
              </h3>
              <p>
                Always inspired by the creativity,
                colors, composition, and execution that captures empathy and the
                human experience.{" "}
              </p>
              <p style={{ fontSize: "0.7em", color: colors.grey600 }}>
                Some of my favorites -
                <br />
                <a
                  href="https://www.youtube.com/watch?v=8Bk1RV2yyJ4&ab_channel=JoBloStreaming%26TVTrailers"
                  rel="noreferrer"
                  target="_blank"
                >
                  Fleabag S2
                </a>
                <br />
                <a
                  href="https://www.youtube.com/watch?v=d6kBeJjTGnY&t=12s&ab_channel=vizmedia"
                  rel="noreferrer"
                  target="_blank"
                >
                  Hunter X Hunter
                </a>
                <br />
                <a
                  href="https://www.youtube.com/watch?v=4OiMOHRDs14&ab_channel=MadmanAnime"
                  rel="noreferrer"
                  target="_blank"
                >
                  Princess Mononoke
                </a>
              </p>
            </QuotesCard>
            <QuotesCard>
              <iframe
                title="spotify"
                src="https://open.spotify.com/embed/playlist/5ANOecKy2kmFLw8kMPCnCY"
                width="100%"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
              <p>
                Aside from watching movies and taking pictures, I also make
                spotify playlists in my spare time
              </p>
            </QuotesCard>
          </ColContainer>
          <ColContainer>
            <QuotesCard>
              <Image
                sizes={photography}
                alt={"photography"}
                style={{
                  width: "60%",
                  margin: "0 auto",
                  borderRadius: "0.5em",
                  marginBottom: "2em",
                }}
              />
              <p
                style={{
                  margin: "0 auto",
                  marginTop: "auto",
                  // alignSelf: "flex-end",

                  marginBottom: "0",
                }}
              >
                Photography is my way of trying to remember the feelings and
                experiences felt in that moment.{" "}
                <a
                  href="https://vsco.co/akosasih"
                  rel="noreferrer"
                  target="_blank"
                >
                  Click here to see more&nbsp;
                  <span>→</span>
                </a>
              </p>
            </QuotesCard>
            <QuotesCard>
              <Image
                sizes={travel}
                alt={"travel"}
                style={{
                  width: "60%",
                  margin: "0 auto",
                  borderRadius: "0.5em",
                  marginBottom: "2em",
                }}
              />
              <h3>Exploration</h3>
              <p>
                Travel, to learn new things - about people, culture, design. Working on interesting projects,
                to be challenged and grow through collaboration in interdisciplinary teams.
              </p>
              <p style={{ fontSize: "0.7em", color: colors.grey600 }}>
                Some things I've done -
                <br />
                <a
                  href="https://www.facebook.com/ustdesign"
                  rel="noreferrer"
                  target="_blank"
                >
                  Co-founder of UST Design
                </a>
                <br />
                <a
                  href="https://medium.com/ust-design/ui-ux-workshop-part-1-8f0726448e25"
                  rel="noreferrer"
                  target="_blank"
                >
                  Taught a UI/UX Workshop
                </a>
                <br />
                <a
                  href="https://robotics.ust.hk/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Hardware engineer for HKUST Robotics Team
                </a>
              </p>
            </QuotesCard>
          </ColContainer>
        </QuotesContainer>
      </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    avatar: file(absolutePath: { regex: "/about-face.JPG/" }) {
      childImageSharp {
        sizes(maxWidth: 800) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    photography: file(absolutePath: { regex: "/photography.jpg/" }) {
      childImageSharp {
        sizes(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    travel: file(absolutePath: { regex: "/travel.jpg/" }) {
      childImageSharp {
        sizes(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`
